import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { SharedModule } from './modules/shared/shared.module';
import { LoaderModule } from './components/loader/loader.module';
import { RequestInterceptor } from './Interceptors/request.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material';
import { HighchartsChartModule } from 'highcharts-angular';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoaderModule,
    SharedModule,
    MatNativeDateModule,
    // HighchartsChartModule
  ],
  providers: [    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
