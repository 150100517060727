import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilityService } from '../modules/shared/services/utility.service';
import { LoaderService } from '../modules/shared/services/loader.service';
import { LOGIN } from '../constant/absolute-routes/absolute-routes';
import { PAYMENT } from '../constant/routes/routes';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private utilityService: UtilityService,
        private loaderService: LoaderService
    ) {
    }
    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     this.showLoader();
    //     return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
    //       if (event instanceof HttpResponse) {
    //         this.onEnd();
    //       }
    //     },
    //       (err: any) => {
    //         this.onEnd();
    //     }));
    //   }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headers = {};
        const token = this.utilityService.getAuthToken();
        if (token) {

            headers['authorization'] = 'Bearer ' + token;
        }

        request = request.clone({
            setHeaders: headers
        });
        this.loaderService.showLoader();
        return next.handle(request).pipe(
            tap(
                (data) => {
                    if (data instanceof HttpResponse) {
                        this.loaderService.hideLoader();
                    }
                },
                (err: any) => {
                    this.loaderService.hideLoader();
                    if (err instanceof HttpErrorResponse) {
                        if (err.error.message) {
                            this.utilityService.showAlert(err.error.message, 'Error')
                            
                        }else{
                        this.utilityService.showAlert('Please check your connection', 'Error')

                        }
                        if (err.status === 401 || err.error.responseType === 'UNAUTHORIZED') {
                            this.utilityService.clearStorage();
                            this.router.navigate([LOGIN]);
                        }
                    }
                }
            ));
    }
}
