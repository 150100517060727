// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://elearningapi.appskeeper.com/api/',
  // url:'http://elearningqaapi.appskeeper.com/api/',
  // url:'http://elearningstgapi.appskeeper.com/api/',
 // url: 'https://api.urban-sandbox.com/api/',
  url:'https://elearningapi.appskeeper.com/api/',

  fileAccessUrl: 'assets',
  // config: {
  //   AWS_ACCESS_KEY: 'AKIATK3JGNCTILZ4EJNZ',
  //   AWS_SECRET_KEY: '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',
  //   AWS_REGION: 'us-east-1',
  //   BOOK_BUCKET: 'elearningapp',
  //   AWS_BUCKET: 'elearningapp',
  //   BUCKET_URL: 'https://elearningapp.s3.amazonaws.com/',
  //   CHANNEL_VIDEO_FOLDER: 'channelvideo'
  // }
  config: {
    AWS_ACCESS_KEY: 'AKIATK3JGNCTB5B234PS',
    AWS_SECRET_KEY: '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',
    AWS_REGION: 'us-east-1',
    BOOK_BUCKET: 'elearningapp',
    AWS_BUCKET: 'elearningapp',
    BUCKET_URL: 'https://elearningapp.s3.amazonaws.com/',
    CHANNEL_VIDEO_FOLDER: 'channelvideo'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
