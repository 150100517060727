<div class="confirmation-popup dialog-box">
	<div class="dialog-header">
		<h6 class="heading">{{title}}</h6>
		<mat-icon (click)="onNoClick()">close</mat-icon>
	</div>
	<div class="dialog-body">

		<div class="modal_message">
			<p>{{message}}</p>
			<div class="content" *ngIf="showTextBox" mat-dialog-action>
				<h4 style="text-align:center ;margin-top: 20px;">Reason for This Action</h4>
				<mat-form-field>
					<textarea matInput placeholder="Type here.." [(ngModel)]="responseData.note"
						name="note"></textarea>
				</mat-form-field>
			</div>
		</div>
		<div class="buttons-cover text-center">
			<button mat-stroked-button *ngIf="showCancelButton" (click)="onNoClick()"
				class="btn btn-secondary modal-btn no">
				{{no}}
			</button>
			<button mat-raised-button color="primary" class="btn btn-primary"
				[disabled]="(!responseData||!responseData.note||responseData.note.trim()=='') && showTextBox"
				[mat-dialog-close]="responseData" (click)="confirm()" cdkFocusInitial>
				{{yes}}
			</button>
		</div>
	</div>
</div>