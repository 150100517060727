import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-image-model',
  templateUrl: './view-image-model.component.html',
  styleUrls: ['./view-image-model.component.scss']
})

export class ViewImageModelComponent implements OnInit {
imageData ;
  constructor(
    
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewImageModelComponent>,
  ) {
    
   }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close()
  }
}
