import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {HomeGuard} from './guards/home/home.guard';
import { ACCOUNT} from './constant/routes/routes';
import { AccountGuard } from './guards/account/account.guard';

const appRoutes: Routes = [
    {path: '', redirectTo: '', pathMatch: 'full'},
    { path: ACCOUNT, loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule), canLoad: [AccountGuard], canActivate: [AccountGuard]},
    { path: 'home', loadChildren: () => import('./modules/features/home/home.module').then(m => m.HomeModule)},
    { path: 'privacy_policy', loadChildren: () => import('./modules/features/public-privacy-policy/public-privacy-policy.module').then(m => m.PublicPrivacyPolicyModule)},
    { path: 'terms_and_condition', loadChildren: () => import('./modules/features/public-terms-conditions/public-terms-conditions.module').then(m => m.PublicTermsConditionsModule)},
    { path: 'faqs', loadChildren: () => import('./modules/features/public-faq/public-faq.module').then(m => m.PublicFAQModule)},
    { path: 'about_us', loadChildren: () => import('./modules/features/public-about-us/public-about-us.module').then(m => m.PublicAboutUsModule)},
     { path: '', loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule), canLoad: [HomeGuard], canActivate: [HomeGuard]},
    // { path: '**', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {scrollPositionRestoration: 'top',preloadingStrategy: PreloadAllModules})
    ],
    exports: [
        RouterModule
    ],
})

export class AppRoutingModule { }
